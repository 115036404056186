@import "../../assets/scss/theme.scss";

.radio-toolbar{
  label {
    display: inline-block;
    background-color: $gray-300;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
  }
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
    &:checked + label {
      background-color: $primary;
      border: none;
      color: $white;
    }
  }
  // color option
  &.coloropt-radio{
    input[type="radio"] {
      &:checked + label {
        &:after{
          content: "\F012C";
          position: absolute;
          font-family: "Material Design Icons";
          color: $white;
          font-size: 14px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        &.bg-light{
          &:after{
            color: $primary;
          }
        }
      }
    }

    label{
      border: 1px solid $card-bg !important;
        &.bg-light{
          background-color: $card-bg;
          border-color: $primary !important;
        }
    }
  }

  // image option
  &.imgopt-radio{
    label{
      padding: 0;
      overflow: hidden;
      background: transparent;
    }
    input[type="radio"] {
      &:checked + label {
        &::before{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($primary, 0.6);
        }

        &:after{
          content: "\F012C";
          position: absolute;
          font-family: "Material Design Icons";
          color: $white;
          font-size: 14px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
}

#radio-title {
  margin-bottom: 5px;
}
// .data-range-filter .rdrDefinedRangesWrapper{
//   display: none;
// }

// .data-range-filter .rdrDateDisplayWrapper{
//   display: none;
// }



.date-input-span{border:none !important; box-shadow: none !important;}
.date-input-span .date-input-icon { position: absolute; right: 15px;  top: 10px;}
.search-datatb{margin-top: 20px;}
.date-pick-div {
  position: absolute;
  background: #fff;
  float: left;
  z-index: 999;
  box-shadow: 0px 4px 4px #eee;
}
.apply-date-btn{    margin: 0px 10px  10px 0px!important;
  text-align: center;
  
  float: none;}
  .clear-date-btn{
    border: 1px solid #ddd;
  }
  .apply-date-btn-sec{text-align: center;}
/* .custom-dp .rdrMonth {
  padding: 0 0.833em 3.666em 0.833em;
} */
.custom-dp{position: relative;
}
.date-range .rdrDefinedRangesWrapper{
 display: none;
}
.rdrDateDisplayWrapper{
  background: none !important;
}
.date-range .rdrDateDisplayWrapper{
  display: none;
 }

 .date-range .rdrMonthName{
  display: none;
 }