.Coupon-Create-textarea{
    display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    line-height: 1.5;
    color: #151A30;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Coupon-Create-date{
    display: block;
    padding: 15px;
    background-color: #fff !important;
    border-color: #ced4da !important;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    width: 100%;
}
.Coupon-Create-dropdown{
    display: flex;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #151a30;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 48px;
}
.Coupon-Create-submit-btn{
    background-color: #8338EC;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    border: none;
    padding: 12px 25px;
    margin-top: 20px;
    text-align: center;
}
.react-datepicker{
    font-size: 16px !important;
}

.textdanger{
    color: red !important;
  font-size: 12px !important;
}

#sidebar-menu ul li a:hover {
    background: #8338EC;
}
.metismenu li {margin-bottom:10px;}
.resend-code {
    cursor: pointer;
    
}
.resend-code:hover {
    color: #000 !important;
}
.daterangepicker {
    width: 52%;
}
.daterangepicker .drp-calendar {
    max-width: 340px;
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td{
    line-height: 11px;
}
.daterangepicker.opensright:before {
    left: auto;
    right: 29px;
}
.daterangepicker.opensright:after {
    right: 30px;
    left: auto;
}
.table-no-check tr th:last-child{
    text-align: left !important;
}
.table-no-check tr td:last-child{
    text-align:left !important
}
.custom-table .table-no-check tr th:last-child{
text-align: right !important;
}
.custom-table .table-no-check tr td:last-child{
    text-align:right !important
}
.border-part-new{
    width: 2px;
    height: 30px;
    background: #d3d3d3;
    margin: 0 10px;
}
.pt6{
    padding-top: 6px;
}
.css-1hb7zxy-IndicatorsContainer{
    display: none !important;
}
.css-1g7k5uv-control, .css-yk16xz-control {
    height: 48px;
    font-size: 16px !important;
    display: block;
    width: 100%;
    padding:0.3rem 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #151A30;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-n2lk5y-control, .css-1pahdxg-control {
    height: 48px !important;
    font-size: 16px !important;
    display: block;
    width: 100%;
    padding:0.3rem 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #151A30;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Coupon-table-switch .form-check-input{
    width: 90px !important;
}
.Coupon-table-switch .form-check-input:checked{
    background-color: rgba(48, 224, 163, 0.5) !important;
}
.new-btn-border{background: transparent !important;
    border: 1px solid #000 !important;
    color: #000 !important ;
}
.pickdiv{
    position: relative;
}
.pickdiv i {
    right: 10px;
    position: absolute;
    top: 31%;
    font-size: 18px;
    cursor: pointer;
}
.swal-toast-custom {
    z-index: 9999 !important; /* Ensure visibility */
    margin-top: 70px !important; /* Adjust the distance from the top */
  }
  