.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}
.input-chck {
  width: 1.3em;
  height: 1.3em;
}
.form-check-input:checked {
  background-color:#8338EC;
  border-color: rgba(48, 224, 163, 0.5);
}
.form-check-input{
  background-color: rgba(119, 119, 119, 0.2);
  border: 1px solid rgba(119, 119, 119, 0.2) !important;
} 
form .btn{
  background-color: #8f9bb3;
  border-color: #8f9bb3;
  font-size: 13px;
}

.form-control {
  &::placeholder {
    color: rgba(119, 119, 119, 0.9)
  }
}
.react-daterange-picker__wrapper {
  display: flex;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #151a30;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-daterange-picker {
  display: block;
}
.pagination .page-link {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
}
.pagination .page-link:hover {
  color: #777777 !important;
}
.pagination .page-item.active .page-link {
  &:hover {
    color: #fff !important;
  }
}
.pagination .page-item {
  margin: 0px 3px;
}
.react-bootstrap-table {
  td {
    p {
      margin-bottom: 0px;
    }
    .mobile16 {
      font-size: 16px;
      color: #151a30;
    }
    .btn-icon {
      margin-left: 10px;
    }
  }
}
.badge {
  padding: 5px 10px !important;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  height: auto;
  display: flex;
  .bx{
    top: 0px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.btn:focus {
  color: #fff !important;
}
.view-all-link {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #777777;
}
.table-no-check tr th span{
  background-position: top 6px right 2px;
  padding-right: 17px;
}
.badge-soft-active {
  background-color: #30e0a1 !important;
  color: rgba(36, 36, 36, 0.6) !important;
}
.badge-soft-marked_for_deletion{
  background-color: #E64A19 !important;
  color: #fff !important;
}
.field-text1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #777777;
  margin: 0px;
  height: 48px;
}
.white-break {
  white-space: nowrap;
}
.text-gray {
  color: #777777;
}
.bg-unknown {
  background-color: #ff7373 !important;
  border-radius: 50px;
  color: #262626;
}
.bg-partial {
  background-color: #ffdc82 !important;
  border-radius: 50px;
  color: #262626;
}
.bg-success {
  background-color: #82ffb4 !important;
  border-radius: 50px;
  color: #262626;
}
.badge-soft-success {
  background-color: #30e0a1 !important;
  border-radius: 50px;
  color: rgba(36, 36, 36, 0.6);
}
.badge-soft-archived {
  background-color: #ced4da !important;
  color: rgba(36, 36, 36, 0.6);
}
.space-noWrap {
  white-space: nowrap;
}
.multiSelect2 {
  button {
    display: flex;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #151a30;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 48px;
  }
}
.font-size-20 {
  font-size: 20px;
  font-family: "Inter", sans-serif;
}
.font-size-16 {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.font-size-11px {
  font-size: 11px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.table-twiceBar tr th:last-child {
  text-align: end;
}

.textarea-content.border-bottom-gray2.pb-4.description {
  textarea {
    min-height: 128px;
  }
}
.table-twiceBar tr th:last-child{
    text-align: end;
    padding-right: 36px !important;
}
.tableNotificationNew tr th:nth-child(2){
  width: 40% !important;
}
.tableNotificationNew tr td:nth-child(2){
  width: 40% !important;
}
.tableNotificationNew tr th:nth-child(3){
  text-align: end;
  padding-right: 0px;
}
.tableNotificationNew tr th:nth-child(4){
  max-width: 115px;
  width: 115px;
}
.tableNotificationNew tr td:nth-child(4){
  max-width: 115px;
  width: 115px;
}
.btn-success{background-color: #30E0A1 !important;}
.form-action .btn-primary{background-color: #8338EC !important;border-color: #8338EC !important;}

.profile-picture24 {
  height: 300px;
}
.profile-picture24 img{
  width: 100%;
  height: 100%;
}
.serviceShowMedia img{
  object-fit: cover;
  width: 80%;
  margin: auto;
  height: 300px;
}
.account-pages{
  .btn-primary{
    background-color: #8338EC !important;
    border-color: #8338EC !important;
  }
  .form-check-input:checked{
    background-color: #8F9BB3 !important;
    border-color: #8F9BB3 !important;
  }
}
.form-switch-lg .form-check-input{
  width: 63px;
  height: 28px;
  cursor: pointer;
  margin-top: 0px !important;
  left: 0px;
}
.form-switch{
  position: relative;
  margin-bottom: 0px !important;
  .form-check-label{
    color: rgba(36, 36, 36, 0.6);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    position: absolute;
    top: 6px;
    right: 9px;
    &.checked{
      right: 31px;
      top: 6px;
    }
  }
}
table{
  th{
    padding: 12px 15px !important;
  }
  td{
    padding: 12px 15px !important;
  }
}

// Sidebar Collpased

.vertical-collpsed{
  .vertical-menu{
    .navbar-brand-box{
      padding: 0px 15px;
    }
    #sidebar-menu{
      .metismenu{
        padding: 10px !important;
        li:hover{
          a{
            width: auto;
          }
        }
        a{
          padding: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            display: none !important;
          }
          i{
            text-align: center;
          }
        }
      }
    }
  }
}
#vertical-menu-btn i{color: #333 !important;}
.userProfile21{
  position: relative;
  width: 90px;
  height: 90px;
  img{
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
  .inputFile{
    width: 30px;
    height: 30px;
    background-color: #8338EC;
    border: none;
    position: absolute;
    bottom: 8px;
    right: -7px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    input{
      width: 30px !important;
      height: 30px;
      border-radius: 50%;
      opacity: 0;
      cursor: pointer;
      z-index: 9;
      position: absolute;
    }
    i{
      color: #fff;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
.title-width{
  b{
    width:100px;
  }
}
.orders-table-main-div .table-bordered{
  border: none !important;
}
.orders-table-main-div thead
{
  color: #777777;
    border-color: #eff2f7;
    background-color: #f8f9fa;
}

.test {
  width: 50px;
  height: 50px;
  border: none;
  background: white;
  font-family: 'Inter';
  font-weight: 500;
  
}
.dropup{
    display: none;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.dropdown{
    display: none;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.caret{
    display: none;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}


.custom-badge {
  background-color: yellow;
  color: white;
}