#page-topbar{
  z-index: 1092 !important;
}

.purple-color-text1 {
  color: rgb(131, 56, 236) !important;
}

.cross-btn-main-new::after{
  background-image: url('./images/close.svg') !important;
  background-size: 1rem !important;
  display: block;
}
.social-check-email-list , .social-check-phone-list {
    padding: 0;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-content: flex-start;
}
.social-check-email-list li, .social-check-phone-list li {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 12px 0;
}

.social-check-email-list li p , .social-check-phone-list li p {
    color: #777;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    min-width: 100px;
    text-transform: capitalize;
}
.ps31{
  padding-left: 31px !important;
}
 /* new styles  */

 .main-or-bg {
	/* background-image: url('../image/BG-Banner.png'); 	 */
	/* background-image: url('./images/BG\ Banner.png'); 	 */
	background-size: 100%;
	background-repeat: no-repeat;

}
.main-or-bg-100{
	background-size: cover !important;
}
.header-icon img{
	width: 25px;
}
.image-card-new{
  border-radius: 15px;
}
.slider-area .carousel-wrap {
  
  
  
  position: relative;
}
select{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  background-image: url('./images/dropdown-arrow.png');
  background-repeat: no-repeat;
  background-position: 95% center;
}
/* fix blank or flashing items on carousel */
.slider-area .owl-carousel .item {
  position: relative;
  z-index: 100; 
  --webkit-backface-visibility: hidden; 
}

/* end fix */
.slider-area {
	padding:15px 0px;
}
.slider-area .owl-nav > div {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  color: #cdcbcd;
}

.slider-area .owl-nav i {
  font-size: 22px;
}
.slider-area .owl-nav .owl-prev {
 		left: 20px;
    border-radius: 50%;
    background: #fff !important;
    top: 40%;
    position: absolute;
    width: 50px;
    height: 50px;
    color: #000 !important;
  
}

.slider-area .owl-nav .owl-next {
    right: 20px;
    border-radius: 50%;
    background: #fff !important;
    top: 40%;
    position: absolute;
    width: 50px;
    height: 50px;
    color: #000 !important;
}
.slider-area .item  {
	border-radius: 10px;
}
.slider-card-person {	
	position: relative;
}
.slider-area .slider-card-person .slide-img {
	width: 100%; 
	height: 350px;
	object-fit: cover;
	border-radius: 10px;
}
.slider-card-person {	
	position: relative;
}
.slider-area .slider-card-person .slide-img {
	width: 100%; 
	height: 350px;
	object-fit: cover;
	border-radius: 10px;
}
.upload-div-slider{
		position: absolute;right: 0;
		bottom: 0px;
		background: #fff;
		color: #000;
		border-radius:5px 0px 0px 0px;
		padding:8px 15px;
}
.upload-div-slider img {
		width: 20px !important; 
		margin-right: 10px;
}
.upload-div-slider a{
		display: flex;
		text-decoration: none;
		color: #000000;
		font-weight: 500;
}
.grey-card-new-contain{
  background-color: #F9F9FA;
  padding: 15px;
  border-radius: 15px;
}
.contain-main-div-text h4{
  color: #230F40;
  font-size: 20px;
  font-weight: 600;
}
.contain-main-div-text p{
  color: #7B6F8C;
  font-size: 14px;
}
.bottom-hr{
			border-bottom: 1px solid #E9E7EC;
			opacity: 1;
}
.verif-card-purple-main{
  border-radius: 40px;
  background-image: linear-gradient(268deg, #6738EC 19.47%, #8338EC 78.98%);
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
}

.main-info-card{
	background: #fff;
	border-radius: 15px;
	padding:  15px 0px;
	/* box-shadow:0 0 35px rgba(0, 0, 0, 0.1) */
}
.personal-info-area1{margin: 15px 25px;}
.personal-info-area1 .accordion-item{
border: 1px solid #F4F4F4 !important;
box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.04);
}
.accordion-button:hover{
  z-index: 0 !important;
}
.accordion-button{
  position: inherit !important;
}
.personal-info-area1 .accordion-item .accordion-header .accordion-button .check-info h3{
color: #230F40;
font-size: 26px;
font-weight: 600;
}
.check-info h3{
  color: #230F40;
  font-size: 26px;
  font-weight: 600;
  padding: 10px;
}
.personal-info-area1 .accordion-item .accordion-header .accordion-button .check-info p{
  color: #7B6F8C;
  font-size: 12px;
  font-weight: 500;
}
.personal-info-area1 .detailcard-new label{
  color: #BDB7C6;
font-size: 12px;
font-weight: 500;
}
.personal-info-area1 .detailcard-new h5{
  color: #230F40;
font-size: 20px;
font-weight: 600;
}
.accordian-theme .accordion-button.collapsed{
  background-color: #F9F9FA !important;
  border: 1px solid #E9E7EC !important;
}
.accordian-theme .accordion-button.collapsed .accordion-button::after{
  background-color: #E9E7EC;
}
.accordian-theme .accordion-button {
  background-color: transparent !important;
  border: none;
  border-radius: 8px !important;
  margin: 0px 0px;
  box-shadow: none;
  color: #212529 !important;
}
.accordion-button::after{
  
  background-color: #E9E7EC;
  /* background-image: url('../image/downarrow.svg') !important; */
  background-image: url('./images/downarrow.svg') !important;
  width: 35px;
  height: 35px;
  background-position: center;
  background-size: 1rem !important;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(35, 15, 64, 0.12);
}
.accordion-button:not(.collapsed)::after{
  background-color: #fff !important;

 
}

.container1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tooltip{
  position: absolute;
  display: inline-block;
  margin-top: 10%;
  opacity: 1;
  padding: 5px;
  border-radius: 5px;
}
.tooltip:hover{
  background: #E9E7EC;
}
.tooltiptext{
  width: 250px;
  background-color: #ffffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -120px;
  visibility: hidden;
  box-shadow: 0px 4px 11px #230f4015;
}

.tooltiptext::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  margin-left: -5px;
  border-width: 5px;
  box-shadow: 0px 4px 11px #230f4015;

}
.tooltip:hover .tooltiptext{
  visibility: visible;
}
.accordion-collapse .detailcard-new{
  border:1px solid #E9E7EC;
  padding: 10px 20px;
  border-radius: 4px;
}
.email-card .detailcard-new{
  min-height: 256px;
}
.phone-card .detailcard-new{
  min-height: 256px;
}
.verif-card-purple{
  border-radius: 40px;
  background-image: linear-gradient(268deg, #6738EC 19.47%, #8338EC 78.98%);
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 12px;
}
.verif-card-purple-main{
  border-radius: 40px;
  background-image: linear-gradient(268deg, #6738EC 19.47%, #8338EC 78.98%);
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
}
.w15{
  width: 15px;
}

.notverif-card-dark{
  border-radius: 40px;
  background: #7B6F8C;
  padding: 5px 10px;
  color: #fff;
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 12px;
}
.notverif-card-dark-main{
  border-radius: 40px;
  background: #7B6F8C;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
}

.lh0{
  line-height: 0;
}
.w33{
  width: 33.33%;
}
.fraud-rating-card:hover{
  cursor: pointer;
}
.fraud-rating-card .text-div-main{
  width: -webkit-fill-available;
}
.fraud-rating-card .text-div-main h4{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #4BB04C;
}
.fraud-rating-card .text-div-main p{
  font-size: 12px;
}
.fraud-rating-card{
  background-color: #fff;
  border: 1px solid #E9E7EC;
  padding: 10px !important;
  height: 65px;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 35px;
}
.rating-tooltiptext{
  bottom: 110%;
}
.tooltip .tooltiptext h5{
  font-size: 16px;
  color: #230F40;
  font-weight: 600;
}
.phone-ver-icons-new{
  width: 40px;
  height: 40px;
}
.order-flex {
	display: flex;font-weight: 500;
}
.order-flex  img {
	width: 22px;margin-right: 10px;
}
.light-perpurre-box{
  background: #F3EBFD;
  color: #8338EC;
  border-radius: 4px;
}
.light-perpurre-box .color-right-light{
  color: #8338EC;
}


.accordion-button:not(.collapsed)::after{
  /* background-image: url('../image/toparrow.svg') !important; */
  background-image: url('./images/toparrow.svg') !important;
  transform: rotate(0deg) !important;
}
.cross-btn-main-new::after{
  background-image: url('./images/close.svg') !important;
  background-size: 1rem !important;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem !important;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}
.accordion-button::after {
    background-color: #E9E7EC !important;
    background-image: url(./images/downarrow.svg) !important;
    width: 35px !important;
    height: 35px !important;
    background-position: center !important;
    background-size: 1rem !important;
    border-radius: 50% !important; 
    box-shadow: 0px 2px 4px 0px rgba(35, 15, 64, 0.12) !important;
}

.cross-btn-main-new::after{
  background-image: url('./images/close.svg') !important;
  background-size: 1rem !important;
}

.container1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tooltip{
  position: relative !important;
  display: inline-block !important;
  margin-top: 10% !important;
  opacity: 1 !important;
  padding: 5px !important;
  border-radius: 5px !important;
}
.tooltip:hover{
  background: #E9E7EC;
}
.tooltiptext{
  width: 250px;
  background-color: #ffffff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -120px;
  visibility: hidden;
  box-shadow: 0px 4px 11px #230f4015;
}

.tooltiptext::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  margin-left: -5px;
  border-width: 5px;
  box-shadow: 0px 4px 11px #230f4015;

}
.tooltip:hover .tooltiptext{
  visibility: visible;
}

.modal-backdrop{
  z-index: 1100 !important;
}
.custom-sidebar{
  z-index: 1110 !important;
  width: 720px !important;
}
.phone-ver-icons-new{
  width: 40px;
  height: 40px;
}
.Criminal-card-main{
  min-height: 520px;
}
.Criminal-card-main .light-perpurre-box{
  bottom: 0;
  position: absolute;
  width: -webkit-fill-available;
}
.name-content{

padding: 15px 10px 0px 15px;

}
.name-content h5 {
	font-size: 13px;
	font-weight: 500;font-family: 'Inter', sans-serif;
	color:#BEBDBC;
	margin-bottom: 6px;	
}

.name-content h3 {
	font-size: 22px;
	font-weight: bold;
	color:#1B1B1B;
}
.name-card-full{
	width: 100%;
	background: #fff;
  border: 1px solid #E9E7EC ;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	padding: 10px;
    margin-bottom: 20px;
	  margin-right: 10px;
}
.name-card-full .name-content{

padding: 15px 10px 0px 15px;

}
.name-card-full .name-content h5 {

	font-size: 13px;
	font-weight: 500;font-family: 'Inter', sans-serif;
	color:#BDB7C6;
	margin-bottom: 6px;	
}

.name-card-full .name-content h3 {
	font-size: 22px;	
word-break: break-all;
	font-weight: bold;
	color:#230F40;
  font-weight: 600 !important;
}
  .name-card-full .view-profile {
    padding: 0px 20px;
  }
.bottom-hr{
			border-bottom: 1px solid #E9E7EC;
			opacity: 1;
}
.top-hr{
			border-top: 1px solid #E9E7EC ;
			opacity: 1;
}
.custom-sidebar {
	width: 760px;
}
.pinfo-h3 {
	display: flex;
  align-items: center;
  margin-bottom: 10px; 
}
.pinfo-h3 span{
	font-size: 14px;
	margin-left: 15px;
}
.custom-sidebar .text-reset{
	text-decoration: none !important;
}
.custom-sidebar .offcanvas-header{
	border-bottom: 1px solid #F4F4F4;

}
.offcanvas-header {
  display: flex;
	justify-content: space-between !important;
}
.offcanvas-new-button{
	float: right;
	width: 100%;

}
.offcanvas-new-button a{
	display: flex;
	justify-content: space-between !important;
	color: #8338EC !important;
	align-items: center;
  font-weight: 600;
}
.cursor-pointer-new{
    cursor: pointer;
}
.right-arrrow-icon{
    margin-right: 10px;
}
.pinfo-h3 {
	display: flex;
  align-items: center;
  margin-bottom: 10px; 
}
.pinfo-h3 span{
	font-size: 14px;
	margin-left: 15px;
}
.voip-badge{
	background: #F9EAE8 !important;
	color: #C82E19 !important;
	font-size: 14px !important;
	
}
.pass-badge{
	background: #E8FAEA;
	color: #19C82A;
	font-size: 14px;

}
.badge{
		font-family: 'Inter', sans-serif;
	font-weight: 500;
}
.pinfo-h3 {
	display: flex;
  align-items: center;
  margin-bottom: 10px; 
}
.pinfo-h3 span{
	font-size: 14px;
	margin-left: 15px;
}
.offcanvas-new-button{
	float: right;
	width: 100%;

}
.offcanvas-new-button a{
	display: flex;
	justify-content: space-between;
	color: #8338EC !important;
	align-items: center;
  font-weight: 600;
}

.name-card-h3{
	font-weight: 500 !important;
	font-family: 'Inter', sans-serif;
  font-size: 18px !important;
}
.font-size-8{
  font-size: 8px;
}
.font-size-12{
  font-size: 12px;
}
.font-size-14{
  font-size: 14px;
}
.font-size-16{
  font-size: 16px;
}
.font-size-18{
  font-size: 18px;
}

.font-size-20{
  font-size: 20px;
}

.font-size-22{
  font-size: 22px !important;
}
.font-size-24{
  font-size: 24px !important;
}
.text-gray-light-new{
    color: #7B6F8C !important;
}
.header{
  margin-top: 55px;
}
@media (min-width: 1920px) and (max-width: 2560px)  {
    .mr40{
      margin-right: 40px !important;
    }
    .create-ac-img {
      width: 500px;
    }
}
.color-orange{
  color: #ffaf82;
}
.color-orange {
    color: #CF55C0;
}
.fld-row{
  flex-direction: row !important;
}
.custom-dark-card .card-body{
	color: #fff;
}
.name-card-dropdown:focus, .card-dropdown:active {
    background: #f4f4f4;
    box-shadow: none;
    color: #000;
}
.login-card .card-body h4 {
    font-weight: 600;
    color: #230F40;
    margin-bottom: 20px !important;
}
.profile-dropdown .card{
  border: 1px solid #E9E7EC;
  box-shadow:  0px 1px 14px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
#myChart{
  height: 90px !important;
  width: 90px !important;
}
#myChart .zc-rel{
  position: relative !important;
  bottom: 38px;
  top: auto;
}
#myChart1{
  height: 90px !important;
  width: 90px !important;
}
#myChart1 .zc-rel{
  position: relative !important;
  bottom: 38px;
  top: auto;
}
#myChart1{
  height: 90px !important;
  width: 90px !important;
}
#myChart1 .zc-rel{
  position: relative !important;
  bottom: 38px;
  top: auto;
}
.light-puple-bg-new{
  padding: 11px 15px;
  background-color: #F3ECFE;
  border-radius: 500px;
  color: #8338EC !important;
}
.bottom-hr{
			border-bottom: 1px solid #E9E7EC;
			opacity: 1;
}
.fs-35{
  font-size: 35px;
}
.fs-22{
  font-size: 22px;
}
.theme-color {
  color: #230f40 !important;
}
.profile-phot-div-new .profile-img{
  width: 250px;
  float: right;
}

.w72{
  width: 72px;
}
.theme-color-light{
  color: #7B6F8C;
}
.verif-card-purple-phone{
  border-radius: 40px;
    background-image: linear-gradient(268deg, #6738EC 19.47%, #8338EC 78.98%);
    padding: 5px 10px;
    color: #fff;
    position: absolute;
    top: 82px;
    right: 30px;
    font-size: 12px;
}
.verif-card-purplephone2 {
      border-radius: 40px;
    background-image: linear-gradient(268deg, #6738EC 19.47%, #8338EC 78.98%);
    padding: 5px 10px;
    color: #fff;
    position: absolute;
    top: 148px;
    right: 88px;
    font-size: 12px;
}