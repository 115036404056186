.modal{
  z-index: 1111 !important;
}

.yellow-icon-main {
  font-size: 25px;
}
.fs-22 {
  font-size: 22px;
}
.curser-pointer {
  cursor: pointer;
}
.feedresult-img-main{
  height: auto !important;
  width: auto !important;  
}
