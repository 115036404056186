// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-outline-dark, .btn-dark{
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover{
    color: $light;
  }
}

// example 1

.btn-group-example{
  .btn{
    position: relative;

    &:first-child{
      &::before{
        display: none;
      }
    }

    &:before{
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      border: 1px solid $white;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}


// Example 2

.btn-label{
  position: relative;
  padding-left: 44px;
  .label-icon{
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.1);
    border-right: 1px solid rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.btn-light{
    .label-icon{
      background-color: rgba($dark, 0.1);
      border-right: 1px solid rgba($dark, 0.2);
    }
  }
}

.btn-primary{
  background-color: $primary;
  color: $white;
  font-weight: 700;
  border-radius: 10px;
  &:hover{
    color: $white;
    opacity: 1;
    background-color: $primary;
    border-color: $primary;
  }
}
.btn-success{
  background-color: #30E0A1;
  color: $white;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  &:hover{
    background-color: #30E0A1;
    color: $white;
  }
}
.btn-secondary{
  background-color: #8F9BB3;
  color: $white;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  &:hover{
    background-color: #8F9BB3;
    color: $white;
  }
}
form{
  .btn{
    min-height: 48px;
    font-size: 16px;
    padding: 5px 20px;
    &.btn-sm{
      min-height: 36px;
      font-size: 13px;
      padding: 5px 10px;
    }
  }
}
.svg-black{
  color: #242424;
}
.badge{
  height: 34px;
  padding: 8px 10px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  width: auto;
  display: initial;
  font-size: 12px;
  .bx{
    font-size: 18px;
    position: relative;
    top: 4px;
  }
}

// Custom styles

.letter-spacing-1{letter-spacing: 1px;}
.text-primary{color: $primary;}
.text-gray{color: $gray-800;}
.text-hover-gray:hover{color: $gray-800;}
.font-weight-700{font-weight: 700;}
.font-weight-500{font-weight: 500;}
.form-check-label{color: $gray-800;}
.form-label{color: $gray-800;}
.list-style-none{list-style-type: none;}
.card-light-gray{
  background-color: #F9F9F9;
  box-shadow: 0px 2px 4px rgba(18, 38, 63, 0.16);
}
// 
.border-bottom-gray{
  border-bottom: 2px solid rgba(119, 119, 119, 0.2);
}
.border-bottom-gray2{
  border-bottom: 2px solid #F6F6F6;
}
.background-bg-gray{
  background: rgba(119, 119, 119, 0.1);
  color: $gray-800;
}
.form-control, .select2-selection__control{
  height: 48px;
  font-size: 16px;
}
.text-area-custom{
  min-height: 350px !important;
}
.field-search{
  position: relative;
  background-image: url('./../../../icons/search.svg');
  background-position: top 16px right 10px; 
  background-repeat: no-repeat;
  padding-right: 30px;
}
.home-card23{
  min-height: 140px;
  max-height: 140px;
}
.nav-tabs-custom{
  white-space: nowrap;
  .nav-item{
    flex-grow: 0;
  }
}
.btn-icon{
  background-color: transparent;
  border: none;
  outline: 0px;
  &:hover{
    background-color: transparent;
  }
  &:focus{
    box-shadow: none;
    background-color: transparent;
    border: none;
    outline: 0px;
  }
}
.table-no-check{
  .selection-cell-header{
    display: none;
  }
  .selection-cell{
    display: none;
  }
  tr{
    th{
      &:last-child{
        text-align: right;
      }
      span{
        position: relative;
        background-image: url('./../../../icons/down-arrow.svg');
        background-position: top 6px right 15px; 
        background-repeat: no-repeat;
        padding-right: 30px;
        cursor: pointer;
      }
    }
    td{
      color: $gray-600;
      .text-body{
        color: $gray-600 !important;
      }
      &:last-child{
        text-align: right;
      }
    }
  }
}
.theme-boxed{
  .card-title{
    padding-bottom: 8px;
    margin-bottom: 15px;
    border-bottom: 3px solid #fff;
  }
}
.page-title-bar{
  margin-left: 15px;
  h5{
    color: $gray-500;
    display: flex;
    align-items: center;
  }
  .btn{
    display: flex;
    align-items: center;
    margin-left: 20px;
    .bx{
      font-size: 24px;
    }
  }
}
.financial-table{
  border-bottom: 4px solid #CED4DA;
  min-height: 300px;
  padding-bottom: 30px;
}
.result-accordion{
  .accordion{
    .accordion-item{
      margin-bottom: 20px;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      border: none;
      .accordion-header{
        display: flex;
        align-items: center;
        border-bottom: none;
        .accordion-button{
          color: #495057;
          font-size: 16px;
          padding: 25px;
          min-height: 55px;
          background-color: $white !important;
          border-radius: 20px;
          margin-top: 0px;
          box-shadow: none;
        }
      }
      .accordion-body{
        border-top: 2px solid #CED4DA;
        margin: 25px;
        padding: 0px;
        padding: 25px 0px;
        margin-top: 0px;
      }
    }
  }
}